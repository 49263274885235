
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DetailInfo } from '@/types/qualitySafety'
import IssueOrder from '@/views/qualitySafety/IssueOrder.vue'
@Component({
  components: { IssueOrder }
})

export default class QualitySafety extends Vue {
  private searchInfo = {
    /** 项目名称 */
    projectName: '',
    /** 事件类型 */
    safetyType: '',
    /** 事件状态 */
    safetyStatus: '',
    occurStartDate: '',
    occurEndDate: ''
  }

  private pickerMinDate = ''
  private date =[]

  private pickerOptions = {
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.pickerMinDate = date.minDate
    },
    disabledDate: (time: any) => {
      if (this.pickerMinDate !== '') {
        return (time.getTime() > new Date(this.pickerMinDate).getTime() + 60 * 60 * 24 * 30 * 1000) ||
        (time.getTime() >= Date.now()) ||
        (time.getTime() < new Date(this.pickerMinDate).getTime() - 60 * 60 * 24 * 30 * 1000)
      } else {
        return time.getTime() >= Date.now()
      }
    }
  }

  private safetyType = []
  private page = 1
  private size = 10
  private total = 0
  private tableData: DetailInfo[] =[]
  private loading = false
  private showDialog = false
  private safetyData = ''

  created () {
    this.getData()
    this.getSafetyType()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    if (this.date) {
      this.searchInfo.occurStartDate = this.date[0]
      this.searchInfo.occurEndDate = this.date[1]
    } else {
      this.searchInfo.occurStartDate = ''
      this.searchInfo.occurEndDate = ''
    }
    this.$axios
      .get<{ total: number; list: Array<DetailInfo> }>(this.$apis.qualitySafety.selectQualitySafetyByPage, {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      })
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  getSafetyType () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'safetyType' }).then(res => {
      this.safetyType = res.safetyType || []
    })
  }

  addEvent () {
    this.$router.push({ name: 'qualitySafetyAdd' })
  }

  onDetail (id: string) {
    this.$router.push({ name: 'qualitySafetyDetail', params: { safetyId: id } })
  }

  issueOrder (value: string) {
    this.showDialog = true
    this.safetyData = value
  }

  checkOrder (value: string) {
    this.$router.push({
      name: 'qualitySafetyOrderDetail',
      params: { id: value }
    })
  }
}
