
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { OrderInfo, DetailInfo } from '@/types/qualitySafety'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})

export default class IssueOrder extends Vue {
  @Prop({ default: false }) showDialog!: boolean
  @Prop({ default: {} }) safetyData!: DetailInfo
  private detailInfo: DetailInfo = JSON.parse(JSON.stringify(this.detailInfoBak))
  private formInfo: OrderInfo = JSON.parse(JSON.stringify(this.formInfoBak))

  private date = []

  private rules = {
    taskDetails: [
      { required: true, message: '请输入工单详情', trigger: ['blur', 'change'] }
    ],
    orderType: [
      { required: true, message: '请选择工单类型', trigger: 'change' }
    ],
    startDate: [
      { required: true, message: '请选择作业日期', trigger: 'change' }
    ]
  }

  private submitShow = false
  private typeList = []
  get detailInfoBak () {
    return {
      woId: '',
      safetyId: '',
      projectId: '',
      projectName: '',
      /** 事件类型 */
      safetyTypeName: '',
      safetyType: '',
      /** 事件状态 */
      safetyStatus: '',
      leaderName: '',
      leaderId: '',
      occurAddr: '',
      resourceList: []
    }
  }

  get formInfoBak () {
    return {
      /** 质量安全id */
      planId: '',
      /** 项目id */
      projectId: '',
      /** 工单主题 */
      orderSubject: '',
      /** 工单详情 */
      taskDetails: '',
      /** 工单类型id */
      orderType: '',
      /** 开始时间 */
      startDate: '',
      /** 结束时间 */
      endDate: '',
      /** 作业区域 */
      workArea: '',
      /** 处理人 */
      currentProcessor: '',
      /** 附件列表 */
      fileList: []
    }
  }

  @Watch('showDialog', { immediate: true })
  safetyIdChange (value: boolean) {
    if (value) {
      this.detailInfo = this.safetyData
      this.getParams()
      this.getTypeList()
    }
  }

  getParams () {
    this.formInfo.planId = this.detailInfo.safetyId || ''
    this.formInfo.projectId = this.detailInfo.projectId || ''
    this.formInfo.orderSubject = this.detailInfo.safetyTypeName || ''
    this.formInfo.workArea = this.detailInfo.occurAddr || ''
    this.formInfo.currentProcessor = this.detailInfo.leaderId || ''
    this.formInfo.taskDetails = this.detailInfo.safetyDetail || ''
    this.formInfo.fileList = this.detailInfo.resourceList || []
  }

  getTypeList () {
    this.$axios.get(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  dateChange (date: string[] | null) {
    if (date) {
      this.formInfo.startDate = date[0]
      this.formInfo.endDate = date[1]
    }
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.qualitySafety.placeOrder, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.formInfo = JSON.parse(JSON.stringify(this.formInfoBak))
    this.detailInfo = JSON.parse(JSON.stringify(this.detailInfoBak))
    this.date = []
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).clearValidate()
    })
    this.$emit('update:showDialog', false)
  }
}
